import "./ManageUser.scss";

import { useState } from "react";
import { ColumnDef, createColumnHelper, FilterFn } from "@tanstack/react-table";
import { Link, NavLink } from "react-router-dom";
import Table from "components/Table/Table";
import Input from "components/Input/Input";
import "./integration.scss"

export interface ArticleTitle {
	articleUID: string
	articleTitle: string
	keyword: string
	keywordTraffic: number | null
	internalLinks: number | null
	externalLinks: number | null
	images: number | null
	wordCount: number | null
	isProcessing: boolean
	isGenerated: boolean
	isPosted: boolean
	isFailed: boolean
	isArchived: boolean
	postLink: string
	isUserAdded: boolean
	postedTo: string
	feedback: string
	postedOn: string
}

export default function Articles(props: { articles: Array<ArticleTitle>, showDateColumn?: boolean, userID?: string }) {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [10, 50, 150, 250, 500];

	// ---------------------- STATES ----------------------
	const [searchBoxFilter, setSearchBoxFilter] = useState('');

	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<ArticleTitle>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: ArticleTitle) => row.articleTitle, {
			id: 'title',
			header: "Article Title",
			cell: props => {
				if (props.row.original.isProcessing || props.row.original.isGenerated) {
					return (
						<NavLink to={`/view-article/${props.row.original.articleUID}`} >
							{props.row.original.articleTitle}
						</NavLink>
					)
				} else {
					return props.row.original.articleTitle;
				}
			},
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.articleUID, {
			id: 'article_uid',
			header: "Article UID",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.keyword, {
			id: 'keyword',
			header: "Keyword [Traffic]",
			cell: props => {
				return (
					`${props.row.original.keyword} [${props.row.original.keywordTraffic}]`
				)
			},
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.wordCount, {
			id: 'word_cound',
			header: "Word Count",
			cell: info => {
				if (info.getValue()) {
					return info.getValue()
				} else {
					return "---"
				}
			},
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.isGenerated, {
			id: 'is_generated',
			header: "Generated",
			cell: info => {
				if (info.getValue()) {
					return "Yes"
				} else {
					return "No"
				}
			},
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.isProcessing, {
			id: 'is_processing',
			header: "Processing",
			cell: info => {
				if (info.getValue()) {
					return "Yes"
				} else {
					return "No"
				}
			},
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.isArchived, {
			id: 'is_archived',
			header: "Archived",
			cell: info => {
				if (info.getValue()) {
					return "Yes"
				} else {
					return "No"
				}
			},
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.postedTo, {
			id: 'posted_to',
			header: "Posted To",
			cell: props => {
				if (props.row.original.isPosted) {
					return props.row.original.postedTo
				} else {
					return "--"
				}
			},
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.feedback, {
			id: 'feedback',
			header: "Feedback",
			cell: info => {
				// only show feedback if article is generated and feedback is not "no_feedback"
				if (info.row.original.isGenerated && info.getValue() !== "no_feedback") {
					// capitalize first letter
					return info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1);
				} else {
					return "---"
				}
			},
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.postLink, {
			id: 'article_link',
			header: "Article Link",
			cell: props => {
				if (props.row.original.isPosted) {
					return (
						<Link target="_blank" to={props.row.original.postLink} className={"button is-primary"}>
							View
						</Link>
					)
				} else {
					return (
						<button style={{ backgroundColor: "#86a5ec", cursor: "not-allowed" }} className="button is-primary">
							View
						</button>
					)
				}
			},
			enableGlobalFilter: false,
		}),
	]

	if (props.showDateColumn) {
		const dateColumn = columnHelper.accessor((row: ArticleTitle) => row.postedOn, {
			id: 'date',
			header: "Published Date",
			cell: info => {
				const value = info.getValue();
				if (!value) {
					return "---";
				}
				const date = new Date(value);
				return date.toLocaleString();
			},
			enableGlobalFilter: false,
		});

		columnDefs.splice(columnDefs.length - 1, 0, dateColumn);
	}

	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	return (
		<div className={"card w-100 mt-4"}>
			<div className={"card-header is-flex justify-content-space-between"}>
				<p className={"card-header-title"}>Articles</p>
			</div>
			<div className={"card-content"}>
				<div className={"content"}>
					<div className={"all-users-container"}>
						<div className={"table-controls"}>
							{/* -------------------------- SEARCH BOX -------------------------- */}
							<Input type={"text"}
								className={"table-searchbox"}
								placeholder={"Search for Articles..."}
								value={searchBoxFilter ?? ''}
								onChange={(e) => {
									setSearchBoxFilter(String(e.target.value));
								}} />
						</div>
						<Table tableContentName={"Articles"}
							tableData={props.articles}
							columnDefs={columnDefs}
							pageSizes={pageSizes}
							initialPageSize={pageSizes[0]}
							noDataText={"No Articel Found"}
							searchBoxValue={searchBoxFilter} />
					</div>
				</div>
			</div>
		</div>
	)
}
