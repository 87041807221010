import "./ViewArticle.scss"

import { useState } from "react";

import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { postArticleMutation, getArticleContent, generateNewFeaturedImageMutation, retryFn } from "utils/api";
import { Player } from "@lottiefiles/react-lottie-player";
import PageLoading from "components/PageLoading/PageLoading";
import Markdown from 'markdown-to-jsx';
import Card from "components/Card/Card";
import PageLoadingError from "components/PageLoadingError/PageLoadingError";
import SerperResults from "components/SerperResult/SerperResults";
import { AllIntegrations } from "../ViewTitles/ViewTitles";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import AbunLoader from '../../components/AbunLoader/AbunLoader';
import AbunModal from "components/AbunModal/AbunModal";


export default function ViewArticle() {
	// --------------------- REACT ROUTER PARAMS ---------------------
	let { articleUID, jobId } = useParams();

	// ------------------- STATES -------------------
	const [
		articleTitle,
		setArticleTitle
	] = useState("");

	const [
		processing,
		setProcessing
	] = useState(true);

	const [
		articlePosted,
		setArticlePosted
	] = useState(false);

	const [
		articleContent,
		setArticleContent
	] = useState("");

	const [
		articleContext,
		setArticleContext
	] = useState(null);

	const [
		summaryInputs,
		setSummaryInputs
	] = useState("");

	const [
		mergedSummary,
		setMergedSummary
	] = useState("");

	const [
		crewAIOutput,
		setCrewAIOutput
	] = useState("");

	const [
		articleOutline,
		setArticleOutline
	] = useState("");

	const [
		articleFeatureImage,
		setArticleFeatureImage
	] = useState("");

	const [
		serperResult,
		setSerperResult
	] = useState([]);

	const [integrationWithUniqueID, setIntegrationWithUniqueID] = useState<Array<AllIntegrations>>([]);
	const [publishType, setPublishType] = useState('publish');
	const [selectedIntegration, setSelectedIntegration] = useState("");
	const [selectedIntegrationUniqueID, setSelectedIntegrationUniqueID] = useState("");
	const [requestModalActive, setRequestModalActive] = useState(false);
	const [modalText, setModalText] = useState("");


	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

	// --------------------- QUERY ---------------------
	const {
		isLoading,
		isError,
		error,
		data
	} = useQuery({
		queryKey: ['getArticleContent', articleUID || jobId],
		queryFn: () => getArticleContent(articleUID! || jobId!),
		refetchOnWindowFocus: false,
		retry: retryFn
	})

	// ------------------- FUNCTIONS -------------------
	function renderMainContent() {
		if (processing) {
			return (
				<div className={"is-flex is-flex-direction-column is-align-items-center"}>
					<Player
						autoplay
						loop
						src="https://lottie.host/91a433df-05fa-4ab3-94b2-2c2a0a16a67f/2SoIqH8Kh3.json"
						style={{ height: '300px', width: '300px' }}
					>
					</Player>
					<h2 className={"is-size-3 font-secondary has-text-weight-bold has-text-primary"}>
						Article generation is in progress!
					</h2>
					<p className={"is-size-5 mt-4"}>Article generation should take around 1-2 minutes</p>
				</div>
			)
		} else {
			return (
				<div className={"article-editor-page-editor-container content"} data-color-mode="light">
					<h1>{articleTitle}</h1>
					{articleContent ? <Markdown>{articleContent}</Markdown> : <><h4 className="has-text-centered mt-6 is-size-3">Article Content Not Found</h4></>}
				</div>
			)
		}
	}

	function renderSummaryInputs() {
		if (!processing) {
			let data = JSON.stringify(summaryInputs)
			let inputs = JSON.parse(data)
			return (
				<div className={"article-editor-page-editor-container content"} data-color-mode="light">
					<ul>
						{
							Object.entries(inputs).map(([key, value]) => (
								<li>{`${key}: ${value}`}</li>
							))
						}
					</ul>
				</div>
			)
		}
	}

	function renderArticleOutline() {
		if (!processing) {
			return (
				<div className={"article-editor-page-editor-container content"} data-color-mode="light">
					<ul>
						{
							Object.entries(articleOutline).map(([key, value]) => (
								<li>{`${key}: ${value}`}</li>
							))
						}
					</ul>
				</div>
			)
		}
	}

	const postToBlogMutation = useMutation({
		mutationKey: ['postArticleMutation'],
		mutationFn: postArticleMutation,
		gcTime: 0,
		onSuccess: () => {
			window.location.reload()
		},
		onError: (error) => {
			setRequestModalActive(false);
			if (selectedIntegration.includes("wordpress")) {
				failAlertRef.current?.show(
					"Publishing failed! WordPress rejected the request. Everything is fine on our end. It is most likely a security plugin installed that is causing the issue or if you are using Cloudflare, it blocks the requests and you will have to add our ip 50.116.30.120 and 45.33.6.112 to the whitelist. Please contact customer support via live chat."
				);
			}
			else {
				failAlertRef.current?.show(
					"Publishing failed!. Everything is fine on our end. We've logged the error and are looking into it. Check your site settings and try again later."
				);
			}
		},
	})

	const generateNewFeaturedImage = useMutation({
		mutationKey: ['generateNewFeaturedImageMutation'],
		mutationFn: generateNewFeaturedImageMutation,
		gcTime: 0,
	})

	// --------------------- EFFECTS ---------------------
	useEffect(() => {
		if (data) {
			setArticleTitle(data['data']['article_title']);
			setArticleContent(data['data']['article_content']);
			setProcessing(data['data']['processing']);
			setArticlePosted(data['data']['article_posted']);
			setArticleContext(data['data']['article_context']);
			setIntegrationWithUniqueID(data['data']['integrationWithUniqueID']);
			setSelectedIntegration(data['data']['selectedIntegration'].integrationName);
			setSelectedIntegrationUniqueID(data['data']['selectedIntegration'].integrationUniqueID);

			// these checks are added for already generated articles
			{ data['data']['summary_inputs'] && setSummaryInputs(data['data']['summary_inputs']); }
			{ data['data']['merged_summary'] && setMergedSummary(data['data']['merged_summary']); }
			{ data['data']['crewai_output'] && setCrewAIOutput(data['data']['crewai_output']); }
			{ data['data']['article_outlines'] && setArticleOutline(data['data']['article_outlines']); }
			{ data['data']['article_feature_image'] && setArticleFeatureImage(data['data']['article_feature_image']); }
			{ data['data']['serper_data']['result'] && setSerperResult(data['data']['serper_data']['result'].slice(0, 3)); }
		}
	}, [data]);

	if (isLoading) return <PageLoading />;

	if (isError) return <PageLoadingError message={error as unknown as string} />;

	return (
		<>
			{serperResult && <>
				<Card>
					<h3 className={"is-size-3 has-text-centered"}>Top Articles</h3>
					<SerperResults results={serperResult} />
				</Card>
			</>}

			{summaryInputs &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b>Summary Inputs</b>
						<div className={"content"}>
							{renderSummaryInputs()}
						</div>
					</div>
				</div>
			}

			<AbunModal active={requestModalActive}
				headerText={""}
				closeable={false}
				hideModal={() => {
					setRequestModalActive(false)
				}}>
				<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
					<AbunLoader show={requestModalActive} height="20vh" />
				</div>
				<p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
			</AbunModal>
			{
				!articlePosted &&
				<div className={"publish-options mt-2"}>
					<div className={"publish-btn"} onClick={() => {
						document.querySelector(".publish-dropdown")?.classList.toggle("show");
						// close other dropdowns
						document.querySelector(".copy-txt-dropdown")?.classList.remove("show");
						document.querySelector(".download-dropdown")?.classList.remove("show");
					}}>
						<b>Publish Article</b>
						<svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0.420536 0H4.57946C4.95342 0 5.1404 0.46513 4.87653 0.736306L2.79707 2.87339C2.63281 3.0422 2.36719 3.0422 2.20468 2.87339L0.123469 0.736306C-0.140396 0.46513 0.0465814 0 0.420536 0Z" fill="black" />
						</svg>
					</div>
					<div className="publish-dropdown">
						<div className="card-header">
							Ready to publish your post?
						</div>
						{
							integrationWithUniqueID.length > 0 ?
								<>
									<div className="card-content space-y-4">
										<FormControl fullWidth>
											<InputLabel id="platform-select-label">Platform</InputLabel>
											<Select
												labelId="platform-select-label"
												id="platform-select-label"
												value={selectedIntegrationUniqueID}
												label="Platform"
												onChange={(e) => setSelectedIntegrationUniqueID(e.target.value)}
												MenuProps={{
													// Prevent the dropdown from closing automatically
													PaperProps: {
														onMouseDown: (e) => e.stopPropagation(),
													},
												}}
											>
												{integrationWithUniqueID.map((integration, index) => (
													<MenuItem key={integration.integrationUniqueID} value={integration.integrationUniqueID}
														onClick={() => {
															setSelectedIntegrationUniqueID(integration.integrationUniqueID);
															setSelectedIntegration(integration.integrationName);
														}}
														selected={index === 0 ? true : false}

													>
														{integration.integrationName}
													</MenuItem>
												))}
											</Select>
										</FormControl>

										<FormControl fullWidth>
											<InputLabel id="type-label">Type</InputLabel>
											<Select
												labelId="type-label"
												id="type"
												defaultValue={publishType}
												label="Type"
												onChange={(e) => setPublishType(e.target.value)}
												MenuProps={{
													// Prevent the dropdown from closing automatically
													PaperProps: {
														onMouseDown: (e) => e.stopPropagation(),
													},
												}}
											>
												<MenuItem value="publish">Published Post</MenuItem>
												<MenuItem value="draft">Draft</MenuItem>
											</Select>
										</FormControl>

									</div>

									<footer className="p-4">
										<button className="btn btn-primary ml-auto"
											disabled={processing || articlePosted}
											onClick={() => {
												if (articleUID) {
													if (integrationWithUniqueID.length > 0) {
														setRequestModalActive(true);
														setModalText("Posting to your website blog. Please wait...");
														postToBlogMutation.mutate({
															articleUID: articleUID,
															selectedIntegration: selectedIntegration,
															selectedIntegrationUniqueID: selectedIntegrationUniqueID,
															selected_categories: 1,
															post_status: publishType
														})
													}
												} else {
													console.error("Post to Blog failed. Article UID missing.")
												}
											}}
										>
											Publish
										</button>
									</footer>
								</>
								:
								<div className="card-content space-y-4">
									<p className="text-sm text-muted-foreground">
										No integration found to publish user article.
									</p>
								</div>
						}
					</div>
				</div>
			}

			{mergedSummary &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b>Merged Summary</b>

						<div className={"content"}>
							{mergedSummary}
						</div>
					</div>
				</div>
			}

			{crewAIOutput &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b>Crew AI Output</b>
						<div className={"content"}>
							{crewAIOutput}
						</div>
					</div>
				</div>
			}

			{articleOutline &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b>Outlines</b>
						<div className={"content"}>
							{renderArticleOutline()}
						</div>
					</div>
				</div>
			}

			{articleFeatureImage &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>

						<b style={{ display: "block", marginBottom: "1rem", fontSize: "20px" }}>Featured Image</b>
						<img src={articleFeatureImage} alt="Article Featured Image" />
						{
							!processing &&
							<div className="FeaturedImageSection-Buttons">
								<div className="GenerateNew"
									onClick={() => {
										if (articleUID) {
											setRequestModalActive(true);
											setModalText("Generating new featured image...");
											generateNewFeaturedImage.mutate({ article_uid: articleUID }, {
												onSuccess: (response) => {
													if (response.data && response.data.featured_image) {
														setArticleFeatureImage(response.data.featured_image);
													}
													setRequestModalActive(false);
													successAlertRef.current?.show("Featured image generated successfully!");
													setTimeout(() => {
														successAlertRef.current?.close();
													}, 5000);
												},
												onError: () => {
													setRequestModalActive(false);
													failAlertRef.current?.show("Failed to generate new featured image. Please try again later or contact us for further support.");
													setTimeout(() => {
														failAlertRef.current?.close();
													}, 5000);
												}
											});
										}
									}
									}>
									<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path opacity="0.15" d="M5.125 1C5.125 3.27817 6.97181 5.125 9.25 5.125C6.97181 5.125 5.125 6.97181 5.125 9.25C5.125 6.97181 3.27817 5.125 1 5.125C3.27817 5.125 5.125 3.27817 5.125 1Z" fill="black" />
										<path d="M5.125 1C5.125 3.27817 6.97181 5.125 9.25 5.125C6.97181 5.125 5.125 6.97181 5.125 9.25C5.125 6.97181 3.27817 5.125 1 5.125C3.27817 5.125 5.125 3.27817 5.125 1Z" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
									</svg>
									Generate New
								</div>
								{/* <div className="manual-upload-btn"
												onClick={() => {
													setUploadFeaturedImageModalActive(true);
												}
												}>
												Upload
											</div> */}
							</div>
						}
					</div>
				</div>
			}

			{articleContext &&
				<div className={"card mt-4 w-100"}>
					<div className={"card-content"}>
						<b style={{ display: "block", marginBottom: "1rem", fontSize: "20px" }}>Article Context</b>
						<div className={"content"}>
							{articleContext}
						</div>
					</div>
				</div>
			}

			<div className={"card mt-4 w-100"}>
				<div className={"card-content"}>
					<div className={"content"}>
						{renderMainContent()}
					</div>
				</div>
			</div>
		</>
	)
}