/* eslint-disable jsx-a11y/anchor-is-valid */
import "./ArticleSettings.scss";
import { useEffect, useRef, useState } from "react";
import { useSearchParams, useRevalidator, useParams, useNavigate } from "react-router-dom";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ArticleDetails from "./ArticleDetails";
import Backlinks from "./BacklinksPreference";
import ImageSettings, { ImageSource, KeywordStrategies } from "./ImageSettings";
import { getArticleSettingsData } from "../../utils/api";
import { useQuery } from "@tanstack/react-query";


type Tab = 'images' | 'articleDetails' | 'backlinks';

interface ServerData {
	service: string
	keyword_strategy: KeywordStrategies
	image_source: ImageSource
	all_integrations: Array<string>
	google_search_console_integration: boolean
	google_analytics_integration: boolean
	google_drive_integration: boolean
	has_auto_publish_list: boolean
	website_title: string
	website_description: string
	website_industry: string
	website_icp: string
	username: string
	email: string
	tz: string
	website_domain: string
	competitor_domains: Array<string>
	competitor_edit_underway: boolean
	article_tone_of_voice: string
	external_backlinks_preference: "no-follow" | "follow" | "off"
	article_language_preference: string
	max_internal_backlinks: number
	max_external_backlinks: number
	current_plan_name: string
	images_file_format: string
	website_connection_limit: number
	article_context: string
}

export default function ArticleSettings() {
	// ------------------------- QUERY PARAMETERS -----------------------
	const [searchParams] = useSearchParams();
	const tab = searchParams.get("tab");
	const navigate = useNavigate();
	let { domain } = useParams();

	// ----------------------------- LOADER -----------------------------
	const [pageData, setPageData] = useState({} as ServerData);
	const { revalidate } = useRevalidator();

	const { data, isFetching, error, refetch } = useQuery({
		queryKey: ['getArticleSettingsData'],
		queryFn: () => getArticleSettingsData(domain),
		refetchOnWindowFocus: false,
	});

	// ---------------------------- STATES ---------------------------
	const [currentTab, setCurrentTab] = useState<Tab | string>(tab || 'images');
	const [imageSource, setImageSource] = useState<ImageSource>("ai_image_generation");
	const [imageFilesFormat, setImagesFileFormat] = useState<string>("jpg");

	// ---------------------------- REFS ----------------------------
	const errorAlertRef = useRef<any>(null);
	const successAlertRef = useRef<any>(null);

	// ---------------------------- EFFECTS ---------------------------
	useEffect(() => {
		if (data) {
			setImageSource(data['data'].image_source)
			setImagesFileFormat(data['data'].images_file_format)
			setPageData(data['data']);
		}
	}, [data]);
	useEffect(() => {
		document.title = "Settings | Abun";
	}, []);

	// ---------------------------- FUNCTIONS ---------------------------
	function updatePageData() {
		revalidate();
	}

	// =====================================================================
	// ----------------------------- MAIN JSX -----------------------------
	// =====================================================================
	function renderTab() {
		switch (currentTab) {
			case "images":
				return <ImageSettings errorAlertRef={errorAlertRef}
					successAlertRef={successAlertRef}
					imagesFileFormat={imageFilesFormat}
					setImagesFileFormat={setImagesFileFormat}
					keywordStrategy={pageData.keyword_strategy}
					imageSource={imageSource}
					setImageSource={setImageSource}
					currentPlanName={pageData.current_plan_name}
					updatePageData={updatePageData}
					domain={pageData.website_domain}
				/>;

			case "articleDetails":
				return <ArticleDetails errorAlertRef={errorAlertRef}
					successAlertRef={successAlertRef}
					article_tone_of_voice={pageData.article_tone_of_voice}
					languagePreference={pageData.article_language_preference}
					articleContext={pageData.article_context}
					updatePageData={updatePageData}
					domain={pageData.website_domain}
				/>
			case "backlinks":
				return <Backlinks errorAlertRef={errorAlertRef}
					successAlertRef={successAlertRef}
					external_backlinks_preference={pageData.external_backlinks_preference}
					max_internal_backlinks={pageData.max_internal_backlinks}
					max_external_backlinks={pageData.max_external_backlinks}
					updatePageData={updatePageData}
					domain={pageData.website_domain}
				/>

			default:
				return <ImageSettings errorAlertRef={errorAlertRef}
					successAlertRef={successAlertRef}
					imagesFileFormat={imageFilesFormat}
					setImagesFileFormat={setImagesFileFormat}
					keywordStrategy={pageData.keyword_strategy}
					imageSource={imageSource}
					setImageSource={setImageSource}
					currentPlanName={pageData.current_plan_name}
					updatePageData={updatePageData}
					domain={pageData.website_domain}
				/>;
		}
	}

	function ChangeCurrentTab(tab: Tab) {
		// warn user if they have unsaved changes
		setCurrentTab(tab);
	}



	return (
		<>
			<span className={"back-btn"} style={{ cursor: "pointer" }} onClick={()=> {navigate(-1)}}>
				<svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" strokeOpacity="0.5" strokeWidth="3" />
				</svg>
				<b style={{margin:"1rem", fontSize:"1.6rem"}}>{pageData.website_domain}</b>
			</span>
			<div className="tabs w-100">
				<ul>
					{/* Feature Image */}
					<li className={currentTab === 'images' ? "is-active" : ""} id="images-tab"
						onClick={() => ChangeCurrentTab("images")}>
						<a>Featured Images</a>
					</li>

					{/* Tone of Voice */}
					<li className={currentTab === 'articleDetails' ? "is-active" : ""}
						onClick={() => ChangeCurrentTab("articleDetails")}>
						<a>Article Language</a>  {/* textual Changing article details to article language */}
					</li>
					{/* Backlinks */}
					<li className={currentTab === 'backlinks' ? "is-active" : ""}
						onClick={() => ChangeCurrentTab("backlinks")}>
						<a>Backlinks</a>
					</li>
				</ul>
			</div>
			<div className="tab-content w-100">
				{!isFetching &&
					renderTab()
				}
			</div>
			<ErrorAlert ref={errorAlertRef} />
			<SuccessAlert ref={successAlertRef} />
		</>
	)
}

