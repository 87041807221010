import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import Button from "components/Button/Button";
import { MutableRefObject, useState } from "react";
import { deleteWebsite, saveWebsiteData, retryWebsiteScanning, scanMoreWebsitePages } from "../../utils/api";

interface WebsiteProps {
	userId: number
	websiteId: number
	name: string
	domain: string
	logo: string
	imageSource: string
	websiteScanStatus: string
	hasMorePages: boolean
	pagesScanned: number

	refetchUserData: () => void

	errorAlert: MutableRefObject<any>
	successAlert: MutableRefObject<any>

	className?: string
}

export default function ManageUserWebsite(props: WebsiteProps) {
	// ----------------------- STATES -----------------------
	const [deleteProtection, setDeleteProtection] = useState(true);
	const [
		websiteImageSource,
		setWebsiteImageSource
	] = useState(props.imageSource);
	const [isLoading, setIsLoading] = useState(false);
	const [websiteScanStatus, setWebsiteScanStatus] = useState(props.websiteScanStatus);

	// ----------------------- MUTATION -----------------------
	const saveWebsiteDataMut = useMutation({
		mutationKey: ['saveWebsiteData'],
		mutationFn: saveWebsiteData,
		gcTime: 0,
		onSuccess: () => {
			props.successAlert.current?.show("All website data saved successfully");
		},
		onError: (error) => {
			props.errorAlert.current?.show(`Failed to save website data: ${error}`);
		}
	});

	const deleteWebsiteMut = useMutation({
		mutationKey: ['deleteWebsite'],
		mutationFn: deleteWebsite,
		gcTime: 0,
		onSuccess: () => {
			props.refetchUserData();
		},
		onError: (error) => {
			props.errorAlert.current?.show(`Failed to delete website: ${error}`);
		}
	});

	const retryWebsiteScanningMut = useMutation({
		mutationKey: ['retryWebsiteScanning'],
		mutationFn: retryWebsiteScanning,
		gcTime: 0,
		onSuccess: () => {
			setIsLoading(false);
			setWebsiteScanStatus("finding-sitemaps");
			props.successAlert.current?.show("Re-submitted website scanning task!");
		},
		onError: (error) => {
			setIsLoading(false);
			props.errorAlert.current?.show(`Failed to re-submit website scanning task. Error: ${error}`);
		},
	})

	const scanMoreWebsitePagesMut = useMutation({
		mutationKey: ['scanMoreWebsitePages'],
		mutationFn: scanMoreWebsitePages,
		gcTime: 0,
		onSuccess: () => {
			setIsLoading(false);
			setWebsiteScanStatus("scanning");
			props.successAlert.current?.show("Submitted website scanning task!");
		},
		onError: (error) => {
			setIsLoading(false);
			props.errorAlert.current?.show(`Failed to submit website scanning task. Error: ${error}`);
		},
	})

	// ----------------------- FUNCTIONS -----------------------
	function getWebsiteScanStatusMessage() {
		switch (websiteScanStatus) {
			case "scanning":
				return "Scanning website..."
			case "finding-sitemaps":
				return "Finding website sitemaps..."
			case "scanned":
				return "Website scanned."
			case "queued":
				return "Website scanning task queued."
			case "failed":
				return "Website scanning failed."
			default:
				return "Website scanning has not started yet."
		}
	}

	// ====================================================================
	// ----------------------- MAIN RENDER FUNCTION -----------------------
	// ====================================================================
	return (
		<div className={"card w-100 mt-4"}>
			<div className={"card-content"} style={{ padding: '1rem' }}>
				<div className={"content"}>
					<div className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center user-button"}>
						<div className={"is-flex is-flex-direction-row is-justify-content-center is-align-items-center"}>
							<img src={props.logo} alt="website logo" style={{ width: "64px", height: "64px", borderRadius: "100%" }} />
							<h3 className={"is-size-3 has-text-primary mb-0 mt-0 ml-1"}>
								{props.name}
							</h3>
						</div>

						<div>
							<Button color={"danger"}
								className={"is-outlined  is-small ml-1"}
								disabled={deleteProtection || deleteWebsiteMut.isPending}
								onClick={() => {
									deleteWebsiteMut.mutate({
										user_id: props.userId,
										domain: props.domain
									});
								}}>
								<FontAwesomeIcon icon={"trash-can"} />
							</Button>
							<Button color={"success"} className={"is-small ml-1"} onClick={() => {
								saveWebsiteDataMut.mutate({
									user_id: props.userId,
									domain: props.domain,
									image_source: websiteImageSource,
								});
							}}>
								<FontAwesomeIcon icon={"floppy-disk"} />
							</Button>
							<Button color={deleteProtection ? "danger" : "primary"}
								className={"is-small ml-1"}
								onClick={() => setDeleteProtection(!deleteProtection)}>
								<FontAwesomeIcon icon={"lock"} />
							</Button>
						</div>
					</div>
					<hr className={"dropdown-divider mt-4"} />
					<div className={"is-flex is-flex-direction-column mt-5"}>
						{/* ********************* WEBSITE DETAILS ********************* */}
						<div className={"block"}>
							<p className={"is-size-5"}>
								<FontAwesomeIcon icon={"globe"} />
								&nbsp;
								<a target={"_blank"} className="mr-4" rel={"noreferrer"}
									href={"https://" + props.domain}>{props.domain}</a>
								<p className={"is-small"} style={{ display: "inline" }}>({getWebsiteScanStatusMessage()})</p>
								{websiteScanStatus === "failed" &&
									<Button color={"warning"}
										className={"is-small ml-2"}
										disabled={isLoading}
										onClick={() => {
											setIsLoading(true);
											retryWebsiteScanningMut.mutate({
												user_id: props.userId,
												website_domain: props.domain
											});
										}}>
										Retry
									</Button>
								}
								{websiteScanStatus === "scanned" && props.hasMorePages &&
									<Button color={"info"}
										className={"is-small ml-2"}
										disabled={isLoading}
										onClick={() => {
											setIsLoading(true);
											scanMoreWebsitePagesMut.mutate({
												user_id: props.userId,
												website_domain: props.domain
											});
										}}>
										Scrape More
									</Button>
								}
							</p>
						</div>
						{/* ********************* IMAGE SOURCE ********************* */}
						<div className={"card-header is-flex justify-content-space-between"}>
								<button type={"button"}
									className={"button is-primary is-outlined m-2"}
									style={{ cursor: "pointer" }}
									onClick={() => {
										window.location.href = `/article-settings/${props.domain}`;
									}}>
									Article Settings
								</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
