import { useMutation } from "@tanstack/react-query";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { saveSettingsMutation } from "../../utils/api";


export type FeatureImageTemplates = {
	template_id: string,
	template_name: string,
	sample_image_url: string
	tool_tip: string
	label: string
	order: number
}

export type KeywordStrategies = 'volume' | 'cpc' | 'competition';
export type ImageSource = 'no_image' | 'google' | 'unsplash' | 'ai_image_generation';

interface ImageSettingsProps {
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	imagesFileFormat: string
	setImagesFileFormat: Dispatch<SetStateAction<string>>
	keywordStrategy: KeywordStrategies
	imageSource: string
	setImageSource: Dispatch<SetStateAction<ImageSource>>
	currentPlanName: string,
	domain: string,
	updatePageData: () => void
}

export default function ImageSettings(props: ImageSettingsProps) {

	const {imagesFileFormat, imageSource, setImageSource, setImagesFileFormat} = props;

	// ---------------------------- MUTATIONS ----------------------------
	const saveSettings = useMutation({
			mutationKey: ['saveSettingsMutation'],
			mutationFn: saveSettingsMutation,
			gcTime: 0,
	})

	// ----------------------------- FUNCTIONS ---------------------------

	function changeImageFileFormat(newValue: string) {
		props.errorAlertRef.current?.close();
		setImagesFileFormat(newValue);

		saveSettings.mutate({
			settingsToSave: [
				{ settingName: 'images_file_format', settingValue: newValue },
			],
			domain: props.domain
		}, {
			onSuccess: () => {
				props.updatePageData();
				try {
					props.successAlertRef.current.show("Changes Saved!");
					setTimeout(() => {
						try {
							if (props.successAlertRef.current) {
								props.successAlertRef.current.close();
							}
						} catch (e) { }
					}, 3000);
				} catch (e) { }
			},
			onError: () => {
				props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
				setTimeout(() => {
					try {
						if (props.errorAlertRef.current) {
							props.errorAlertRef.current.close();
						}
					} catch (e) { }
				}, 5000);
			}
		});
	}


	function changeImageSource(newValue) {
		props.errorAlertRef.current?.close();
		setImageSource(newValue);

		saveSettings.mutate({
			settingsToSave: [
				{ settingName: 'image_source', settingValue: newValue },
			],
			domain: props.domain
		}, {
			onSuccess: () => {
				props.updatePageData();
				try {
					props.successAlertRef.current.show("Changes Saved!");
					setTimeout(() => {
						try {
							if (props.successAlertRef.current) {
								props.successAlertRef.current.close();
							}
						} catch (e) { }
					}, 3000);
				} catch (e) { }
			},
			onError: () => {
				props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
				setTimeout(() => {
					try {
						if (props.errorAlertRef.current) {
							props.errorAlertRef.current.close();
						}
					} catch (e) { }
				}, 5000);
			}
		});
	}

	// =====================================================
	// --------------------- MAIN CODE ---------------------
	// =====================================================
	
	return (
		<>
			<div className="card setting-sections p-5">
				{/* -=-=-=-=-=-=-=-=-=-=-= Image Source -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={"settings-section"}>
					<h5 className={"settings-section-title"}>
						Which image source do you want to use in your articles?
					</h5>
					<div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'ai_image_generation'}
								onChange={() => changeImageSource('ai_image_generation')} />
							<span className={"settings-radio-item-title"}>AI Generated</span>
						</label>
						<p className={"settings-radio-item-description"}>
							AI Generated images will be used in the Article.
						</p>
					</div>
					<div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'google'}
								onChange={() => changeImageSource('google')} />
							<span className={"settings-radio-item-title"}>Google Images</span>
						</label>
						<p className={"settings-radio-item-description"}>
							Get images from Google. Using this option might require you to get the necessary permission(s)
							from the photo owner before using them in your article.
						</p>
					</div>
					<div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'no_image'}
								onChange={() => changeImageSource('no_image')} />
							<span className={"settings-radio-item-title"}>No Image</span>
						</label>
						<p className={"settings-radio-item-description"}>
							No images will be used in the Article.
						</p>
					</div>
				</div>

				{/* -=-=-=-=-=-=-=-=-=-=-= Image File Format -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={"settings-section mt-6"}>
					<h5 className={"settings-section-title"}>
						Images File Format:
					</h5>
					<div className="is-flex control mt-4">
						<div className="mr-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"image-format"}
									checked={imagesFileFormat === "webp"}
									onChange={() => changeImageFileFormat("webp")} />
								<span className={"settings-radio-item-title"}>WebP</span>
							</label>
						</div>
						<div className="ml-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"image-format"}
									checked={imagesFileFormat === "png"}
									onChange={() => changeImageFileFormat("png")} />
								<span className={"settings-radio-item-title"}>PNG</span>
							</label>
						</div>
						<div className="ml-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"image-format"}
									checked={imagesFileFormat === "jpeg"}
									onChange={() => changeImageFileFormat("jpeg")} />
								<span className={"settings-radio-item-title"}>JPG</span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
