import { useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import {ColumnDef, createColumnHelper} from "@tanstack/react-table";
import {NavLink} from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {generateArticle, postArticleMutation} from "../../utils/api";

import AbunLoader from '../../components/AbunLoader/AbunLoader';
import Table from "components/Table/Table";
import Input from "components/Input/Input";
import AbunModal from "components/AbunModal/AbunModal";

import shopifyIconPrimary from "../../assets/images/Shopify-logo-primary.png";
import shopifyIconSuccess from "../../assets/images/Shopify-logo-success.png";
import articleIntegrationIcon from "../../assets/images/article-integration-logo.png";
import webflowIconPrimary from "../../assets/images/webflow-logo-primary.png";
import webflowIconSuccess from "../../assets/images/webflow-logo.png";
import wixIconPrimary from "../../assets/images/wix-logo-primary.png";
import wixIconSuccess from "../../assets/images/wix-logo-success.png";
import wordpressIconPrimary from "../../assets/images/wordpress-logo-primary.png";
import wordpressIconSuccess from "../../assets/images/wordpress-logo.png";
import ghostIconSuccess from "../../assets/images/ghost-success-icon.png";
import ghostIconPrimary from "../../assets/images/ghost-primary-icon.png";


interface TitleTableData {
	articleUID: string;
	articleTitle: string;
	keyword: string;
	keywordHash: string;
	keywordTraffic: number;
	wordCount: number;
	isProcessing: boolean;
	isGenerated: boolean;
	isPosted: boolean;
	isFailed: boolean;
	isArchived: boolean;
	postedTo: string,
	postLink: string;
}

export type AllIntegrations = {
	integrationName: string
	integrationUniqueID: string
}

const integrationLogoPrimary = {
	"wordpress": wordpressIconPrimary,
	"webflow": webflowIconPrimary,
	"wix": wixIconPrimary,
	"shopify": shopifyIconPrimary,
	"ghost": ghostIconPrimary,
}

const integrationLogoSuccess = {
	"wordpress": wordpressIconSuccess,
	"webflow": webflowIconSuccess,
	"wix": wixIconSuccess,
	"shopify": shopifyIconSuccess,
	"ghost": ghostIconSuccess,
}

export default function Titles(props: {results: Array<TitleTableData>, integrationWithUniqueID: Array<AllIntegrations>, selectedIntegration: string, selectedIntegrationUniqueID: string}) {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [10, 50, 150, 250, 500];

	// ---------------------- STATES ----------------------
	const [requestModalActive, setRequestModalActive] = useState(false);
	const [searchBoxFilter, setSearchBoxFilter] = useState('');
	const [modalText, setModalText] = useState("");
	const [openDropdown, setOpenDropdown] = useState("");
	const [selectedIntegration, setSelectedIntegration] = useState(props.selectedIntegration);
	const [selectedIntegrationUniqueID, setSelectedIntegrationUniqueID] = useState(props.selectedIntegrationUniqueID);

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);


	// ----------------------- MUTATIONS -----------------------
	const generateArticleMutation = useMutation({
		mutationKey: ['generateArticle'],
		mutationFn: generateArticle,
		gcTime: 0,
		onSuccess: () => {
			window.location.reload()
		},
		onError: (error) => {
			failAlertRef.current?.show(`Failed to submit article for generation. Error: ${error}`);
		},
	})

	const postToBlogMutation = useMutation({
		mutationKey: ['postArticleMutation'],
		mutationFn: postArticleMutation,
		gcTime: 0,
		onSuccess: () => {
			window.location.reload()
		},
		onError: (error) => {
				setRequestModalActive(false);
				if (selectedIntegration.includes("wordpress")) {
					failAlertRef.current?.show(
						"Publishing failed! WordPress rejected the request. Everything is fine on our end. It is most likely a security plugin installed that is causing the issue or if you are using Cloudflare, it blocks the requests and you will have to add our ip 50.116.30.120 and 45.33.6.112 to the whitelist. Please contact customer support via live chat."
					);
				}
				else {
					failAlertRef.current?.show(
						"Publishing failed!. Everything is fine on our end. We've logged the error and are looking into it. Check your site settings and try again later."
					);
				}
		},
	})

	function setIntegrationAndHideDropDownContent(integrate: string) {
		setSelectedIntegration(integrate);
		setOpenDropdown("");
	}

	function getIntegrationName(integrate: string | undefined) {
		if (!integrate) {
			return "wordpress";
		} else {
			if (integrate.includes("wordpress")) {
				return "wordpress";
			} else if (integrate.includes("webflow")) {
				return "webflow";
			} else if (integrate.includes("shopify")) {
				return "shopify";
			} else if (integrate.includes("ghost")) {
				return "ghost";
			} else {
				return "wix";
			}
		}
	}

	function truncateSiteDomain(domain: string) {
		if (domain.includes("Webflow")) {
			domain = domain.replace(new RegExp("Webflow - ", 'g'), '');
		} else if (domain.includes("Wix")) {
			domain = domain.replace(new RegExp("Wix - ", 'g'), '');
		} else if (domain.includes("Shopify")) {
			domain = domain.replace(new RegExp("Shopify - ", 'g'), '');
		} else {
			domain = domain.replace(new RegExp("Wordpress - ", 'g'), '');
		}

		if (domain.length <= 20) {
			return domain;
		} else {
			domain = domain.substring(0, 17) + "...";
		}

		return domain;
	}


	// ---------------------- TABLE COLUMN DEFS ----------------------
	
	const columnHelper = createColumnHelper<TitleTableData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: TitleTableData) => row.articleTitle, {
			id: 'articleTitle',
			header: "Article Title",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: TitleTableData) => row.wordCount, {
			id: 'wordCount',
			header: "Words",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		
		columnHelper.display({
			id: 'action',
			header: "Action",
			enableGlobalFilter: false,
			cell: props => {
				let generated = props.row.original.isGenerated;
				let processing = props.row.original.isProcessing;
				let failed = props.row.original.isFailed;

				if (generated) {
					return (
						<NavLink to={`/view-article/${props.row.original.articleUID}/`} className={`button is-small more-rounded-borders is-success custom-btn`}>
							View Article
						</NavLink>
					);
				}
				else if (processing){
					return (
						<button type="button" className={`button is-small more-rounded-borders is-warning custom-btn`}>Generating...</button>
					);
				}
				else if (failed){
					return (
						<button type="button" className={`button is-small more-rounded-borders is-danger custom-btn`}>Failed</button>
					);
				}
				else{
						return (
							<button type="button" className={`button is-small more-rounded-borders is-success custom-btn`} 
							onClick={ () => generateArticleMutation.mutate({
								article_uid: props.row.original.articleUID
							})
						}>Create Article</button>
						);
				}
			},
		}),

		columnHelper.accessor((row: TitleTableData) => row.wordCount, {
			header: "Publish",
			id: 'post_article',
			enableGlobalFilter: false,
			cell: cellProps => {
				let posted: boolean = cellProps.row.original.isPosted;
				let generated: boolean = cellProps.row.original.isGenerated;
				let postedTo: string = cellProps.row.original.postedTo;
				let articleUID: string = cellProps.row.original.articleUID;
				if (posted) {
					return <>
						<a className={"view-on-blog-link"} href={cellProps.row.original.postLink} data-tooltip-id="my-tooltip" target="_blank" rel="noreferrer" data-tooltip-content="View on Blog" >
							<img className={"image"} src={integrationLogoSuccess[postedTo]} alt={"integration-icon"} />
						</a>
						<Tooltip id="my-tooltip" />
					</>
				} else if (generated) {
					return <>
						<div className="publish-container">
							<button className={"publish-to-blog-btn is-flex is-align-items-center is-justify-content-center"} data-tooltip-id="publish-to-blog" data-tooltip-content={props.integrationWithUniqueID.length > 0 ? "Publish to Blog" : "Connect & Publish Now"}
								onClick={() => {
									if (props.integrationWithUniqueID.length > 0) {
										setRequestModalActive(true);
										setModalText("Posting to your website blog. Please wait...");
										postToBlogMutation.mutate({
											articleUID: cellProps.row.original.articleUID,
											selectedIntegration: selectedIntegration,
											selectedIntegrationUniqueID: selectedIntegrationUniqueID,
											selected_categories: 1
										})
									}
								}}>
								<img className={"image"} src={props.integrationWithUniqueID.length === 0 ? articleIntegrationIcon : integrationLogoPrimary[getIntegrationName(selectedIntegration)]} alt={"integration-icon"} />
							</button>
							{props.integrationWithUniqueID.length > 1 &&
								<div className="dropdown">
									<button className={`dropdown-icon ${openDropdown === articleUID ? "rotate" : ""}`} onClick={() => setOpenDropdown(openDropdown === articleUID ? "" : articleUID)}>&#9662;</button>
									<div className={`dropdown-content ${openDropdown === articleUID ? "show" : ""}`}>
										<h6 className={"dropdown-header"}>
											Choose Integration
										</h6>
										{
											props.integrationWithUniqueID.map((integration, index) => (

												<div key={index} className={"dropdown-item"} onClick={() => {
													setIntegrationAndHideDropDownContent(integration.integrationName);
													setSelectedIntegrationUniqueID(integration.integrationUniqueID);
												}}>
													<input type="radio" name="integration" id={integration.integrationName} checked={selectedIntegration === integration.integrationName} onChange={() => { }} />
													<p>{truncateSiteDomain(integration.integrationName.charAt(0).toUpperCase() + integration.integrationName.slice(1))}</p>
												</div>
											))
										}
									</div>
								</div>
							}
						</div>

						<Tooltip id="publish-to-blog" />
					</>
				} else {
					return <></>
				}
			},
		}),
		
	]

	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	return (
		<div className={"all-users-container"}>
			<div className={"table-controls"}>
				{/* -------------------------- SEARCH BOX -------------------------- */}
				<Input type={"text"}
							 className={"table-searchbox"}
							 placeholder={"Search for Titles..."}
							 value={searchBoxFilter ?? ''}
							 onChange={(e) => {
								 setSearchBoxFilter(String(e.target.value));
							 }}/>
			</div>
			
			{/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
			<AbunModal active={requestModalActive}
				headerText={""}
				closeable={false}
				hideModal={() => {
					setRequestModalActive(false)
				}}>
				<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
					<AbunLoader show={requestModalActive} height="20vh" />
				</div>
				<p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
			</AbunModal>

			<Table tableContentName={"Titles"}
						 tableData={props.results}
						 columnDefs={columnDefs}
						 pageSizes={pageSizes}
						 initialPageSize={pageSizes[0]}
						 noDataText={"No Data Found"}
						 searchBoxValue={searchBoxFilter}/>
		</div>
	)
}
